import React from 'react'
import styles from './Home.module.css';
// import img from '../../Assets/3removebg.png'
import home from '../../Assets/home.png'
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <div className={styles.container}>
      <div className={styles.items}>
        <img src={home} alt="" />
      </div>
      <div className={styles.items}>
        <h2>Бумгоо Kitchen</h2>
        <p>3D Дизайн, гар бэлдмэл, материал сонгох, гэх мэт тавилгын бүх төрлийн ажлуудыг чанарын өндөр төвшинд хийж гүйцэтгэнэ.</p>
        <h3>Та доорх "Үзэх" товч даран бидний өмнө нь гүйцэтгэсэн ажлуудыг харна уу. </h3>
        <Link to={'/insoles'} className={styles.link}>Бараа үзэх</Link>
      </div>
    </div>
  )
}

export default Home
