import React, { useEffect, useState } from 'react'
import styles from './Insoles.module.css'
import axios from 'axios';

const Insoles = () => {
  const [post, setPost] = useState([]);

  useEffect(() => {
    axios.get('https://bumgoo.zyberapi.site/post')
    .then((e) => setPost(e.data))
    .catch((e) => alert(e))
  });

  return (
    <div className={styles.container}>
      <h1>Бидний хийсэн ажлууд</h1>
      <p>Та доошоо гүйлгэн үзнэ үү!</p>
      <div className={styles.items}>
        {/* {[...Array(8)].map((_, index) => (
            <div className={styles.card}>
              <img src={'http://t2.gstatic.com/licensed-image?q=tbn:ANd9GcRaDCZY0AP4L6lrsMycp-94TrQM4OWETrEFzU8YeSPhFEUmpeNL_ew2AkqqSdMJH40O4dorQwnI5jT8zVOhurY'} alt="" />
              <div className={styles.info}>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus accusantium sed facilis consequuntur impedit nostrum incidunt molestiae debitis, possimus praesentium ipsum repudiandae. Dolor mollitia recusandae ipsam perferendis ab, nesciunt distinctio inventore blanditiis magnam eligendi cupiditate id nobis nemo modi illum odio ex dolorum corporis! Libero illum tenetur necessitatibus sed repellat.</p>
              </div>
            </div>
        ))} */}
        {post.map((e) => (
          <div className={styles.card}>
            <img src={'https://bumgoo.zyberapi.site/' + e.cover} alt="" />
            <div className={styles.info}>
              <p>{e.size}</p>
            </div>
          </div>
          ))}
      </div>
    </div>
  )
}

export default Insoles
